/* eslint-disable react/react-in-jsx-scope */
import { Nav, Navbar } from 'react-bootstrap'
import { AuthenticatedTemplate } from '@azure/msal-react'
import { SignInSignOutButtonWithMsal } from "./CustomButtons";

/**
 * NavigationBar component renders a navigation bar with authentication controls.
 * It uses MSAL (Microsoft Authentication Library) to handle login and logout redirects.
 * 
 * @returns {JSX.Element} The rendered navigation bar component.
 * 
 * @remarks
 * - The component uses `useMsal` hook to access the MSAL instance.
 * - It defines `handleLoginRedirect` and `handleLogoutRedirect` functions to manage authentication redirects.
 * - The navigation bar displays different options based on the authentication state using `AuthenticatedTemplate` and `UnauthenticatedTemplate`.
 * @component
 */
export const NavigationBar = (): JSX.Element => {

    return (
        <>
            <Navbar variant="dark" className="navbarStyle">
                <Nav.Link className="navbar-brand" href="/">
                    <div className='oncobitLogo'>
                        <img src="oncobit.png" alt="obit-logo" /> <strong>Portal</strong>
                    </div>
                </Nav.Link>


                <div className="collapse navbar-collapse justify-content-end">
                        <AuthenticatedTemplate>
                            <Nav.Link className="navbarButton" href="/fileview" >
                                File Share
                            </Nav.Link>
                        </AuthenticatedTemplate>

                        <SignInSignOutButtonWithMsal />

                    <div className='oncobitFavicon'>
                        <img src="oncobit_favicon.png" alt="obit-favicon" />
                    </div>
                </div>
            </Navbar>
        </>
    )
}
