import { LogLevel, RedirectRequest, type Configuration } from '@azure/msal-browser'

const reactAppB2CDir = process.env.REACT_APP_B2C_DIR!
const reactAppB2CApiId = process.env.REACT_APP_B2C_API_ID!

const authorityUrl: string = `https://${reactAppB2CDir}.b2clogin.com/${reactAppB2CDir}.onmicrosoft.com/`
const appIdUri: string = `https://${reactAppB2CDir}.onmicrosoft.com/${reactAppB2CApiId}/`

const environment = process.env.REACT_APP_NODE_ENV ?? 'development'
const isDevelopment = ['development', 'production.local'].includes(environment)

// Defined the API endpoint and the Redirect URL
let apiEndpoint = process.env.REACT_APP_REDIRECT_URL!
let redirectURL = process.env.REACT_APP_REDIRECT_URL!

if (typeof apiEndpoint !== 'undefined' && typeof redirectURL !== 'undefined') {
  if (isDevelopment) {
    apiEndpoint = apiEndpoint + (process.env.REACT_APP_API_PORT!)
    redirectURL = redirectURL + (process.env.REACT_APP_PORT!)
  }
  apiEndpoint = apiEndpoint + /api/
}

/**
 * Configuration object for Azure B2C policies.
 * 
 * @property {Object} names - Object containing the names of the policies.
 * @property {string} names.signUpSignIn - Name of the sign-up/sign-in policy.
 * @property {string} names.forgotPassword - Name of the forgot password policy.
 * @property {string} names.editProfile - Name of the edit profile policy.
 * 
 * @property {Object} authorities - Object containing the authorities for the policies.
 * @property {Object} authorities.signUpSignIn - Object containing the authority for the sign-up/sign-in policy.
 * @property {string} authorities.signUpSignIn.authority - Authority URL for the sign-up/sign-in policy.
 * @property {Object} authorities.forgotPassword - Object containing the authority for the forgot password policy.
 * @property {string} authorities.forgotPassword.authority - Authority URL for the forgot password policy.
 * @property {Object} authorities.editProfile - Object containing the authority for the edit profile policy.
 * @property {string} authorities.editProfile.authority - Authority URL for the edit profile policy.
 * 
 * @property {string} authorityDomain - Domain for the authority.
 */
export const b2cPolicies: {
  names: {
    signUpSignIn: string
    forgotPassword: string
    editProfile: string
  }
  authorities: {
    signUpSignIn: {
      authority: string

    }
    forgotPassword: {
      authority: string

    }
    editProfile: {
      authority: string
    }
  }

  authorityDomain: string
} = {
  names: {
    signUpSignIn: 'B2C_1A_SIGNUP_SIGNIN',
    forgotPassword: 'B2C_1A_PASSWORDRESET',
    editProfile: 'B2C_1A_PROFILEEDIT'
  },
  authorities: {
    signUpSignIn: {
      authority: String(authorityUrl + 'B2C_1A_SIGNUP_SIGNIN')

    },
    forgotPassword: {
      authority: String(authorityUrl + 'B2C_1A_PASSWORDRESET')

    },
    editProfile: {
      authority: String(authorityUrl + 'B2C_1A_PROFILEEDIT')
    }
  },

  authorityDomain: String(`${reactAppB2CDir}.b2clogin.com`)
}

/**
 * Configuration object for MSAL (Microsoft Authentication Library).
 * 
 * @constant
 * @type {Configuration}
 * 
 * @property {object} auth - Authentication parameters.
 * @property {string} auth.clientId - The client ID of the application registered in Azure AD B2C.
 * @property {string} auth.authority - The authority URL for the sign-up/sign-in user flow.
 * @property {string[]} auth.knownAuthorities - Array of known authorities (tenant domains).
 * @property {string} auth.redirectUri - The URI to redirect to after login. Must be registered in Azure Portal.
 * @property {string} auth.postLogoutRedirectUri - The URI to redirect to after logout.
 * 
 * @property {object} cache - Cache configuration.
 * @property {string} cache.cacheLocation - Location to store cache. "sessionStorage" is more secure, "localStorage" provides SSO.
 * @property {boolean} cache.storeAuthStateInCookie - Whether to store cache items in cookies as well as browser cache.
 * 
 * @property {object} system - System configuration.
 * @property {object} system.loggerOptions - Logger options.
 * @property {function} system.loggerOptions.loggerCallback - Callback function for logging.
 * @property {LogLevel} system.loggerOptions.loggerCallback.level - Log level (Error, Info, Verbose, Warning).
 * @property {string} system.loggerOptions.loggerCallback.message - Log message.
 * @property {boolean} system.loggerOptions.loggerCallback.containsPii - Indicates if the message contains Personally Identifiable Information (PII).
 */
export const msalConfig: Configuration = {
  auth: {
    clientId: (process.env.REACT_APP_B2C_APP_ID!) ?? '',
    authority: b2cPolicies.authorities.signUpSignIn.authority, // Choose sign-up/sign-in user-flow as your default.
    knownAuthorities: [b2cPolicies.authorityDomain], // You must identify your tenant's domain as a known authority.
    redirectUri: redirectURL, // You must register this URI on Azure Portal/App Registration. Defaults to "window.location.href".,
    postLogoutRedirectUri: `https://login.microsoftonline.com/${reactAppB2CDir}/oauth2/logout?post_logout_redirect_uri=${redirectURL}`    
  },
  cache: {
    cacheLocation: 'sessionStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO.
    storeAuthStateInCookie: false // If you wish to store cache items in cookies as well as browser cache, set this to "true".
  },
  system: {
    loggerOptions: {
      logLevel: LogLevel.Verbose,
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message)
            return
          case LogLevel.Info:
            console.info(message)
            return
          case LogLevel.Verbose:
            console.debug(message)
            return
          case LogLevel.Warning:
            console.warn(message)
            break

            // eslint-disable-next-line no-fallthrough
          default:
        }
      }
    }
  }
}

/**
 * @constant
 * @name protectedResources
 * @description This constant defines the configuration for various protected API resources used in the application.
 * Each resource includes an endpoint and the required scopes for accessing the resource.
 * 
 * @property {Object} apiUploadService - Configuration for the upload service API.
 * @property {string} apiUploadService.endpoint - The endpoint URL for the upload service.
 * @property {Object} apiUploadService.scopes - The scopes required for the upload service.
 * @property {string[]} apiUploadService.scopes.write - The write scopes required for the upload service.
 * 
 * @property {Object} apiDownloadService - Configuration for the download service API.
 * @property {string} apiDownloadService.endpoint - The endpoint URL for the download service.
 * @property {Object} apiDownloadService.scopes - The scopes required for the download service.
 * @property {string[]} apiDownloadService.scopes.read - The read scopes required for the download service.
 * 
 * @property {Object} apiRunAnalyzerService - Configuration for the run analyzer service API.
 * @property {string} apiRunAnalyzerService.endpoint - The endpoint URL for the run analyzer service.
 * @property {Object} apiRunAnalyzerService.scopes - The scopes required for the run analyzer service.
 * @property {string[]} apiRunAnalyzerService.scopes.write - The write scopes required for the run analyzer service.
 * 
 * @property {Object} apiRunReporterService - Configuration for the run reporter service API.
 * @property {string} apiRunReporterService.endpoint - The endpoint URL for the run reporter service.
 * @property {Object} apiRunReporterService.scopes - The scopes required for the run reporter service.
 * @property {string[]} apiRunReporterService.scopes.write - The write scopes required for the run reporter service.
 * 
 * @property {Object} apiUpdateData - Configuration for the update data API.
 * @property {string} apiUpdateData.endpoint - The endpoint URL for the update data service.
 * @property {Object} apiUpdateData.scopes - The scopes required for the update data service.
 * @property {string[]} apiUpdateData.scopes.read - The read scopes required for the update data service.
 * 
 * @property {Object} apiServerSentEvents - Configuration for the server-sent events API.
 * @property {string} apiServerSentEvents.endpoint - The endpoint URL for the server-sent events service.
 * @property {Object} apiServerSentEvents.scopes - The scopes required for the server-sent events service.
 * @property {string[]} apiServerSentEvents.scopes.write - The write scopes required for the server-sent events service.
 */
export const protectedResources: {
  apiUploadService: {
    endpoint: string
    scopes: {
      read: string[]
    }
  }
  apiDownloadService: {
    endpoint: string
    scopes: {
      read: string[]
    }
  }
  apiRunAnalyzerService: {
    endpoint: string
    scopes: {
      write: string[]
    }
  }
  apiRunReporterService: {
    endpoint: string
    scopes: {
      write: string[]
    }
  }
  apiUpdateDataService: {
    endpoint: string
    scopes: {
      read: string[]
      write: string[]
      delete: string[]
    }
  }
  apiSSEService: {
    endpoint: string
    scopes: {
      read: string[]
    }
  }
} = {
  apiUploadService: {
    endpoint: apiEndpoint + 'sas/upload',
    scopes: {
      read: [appIdUri + 'UploadService.Read']
    }
  },
  apiDownloadService: {
    endpoint: apiEndpoint + 'sas/download',
    scopes: {
      read: [appIdUri + 'DownloadService.Read']
    }
  },
  apiRunAnalyzerService: {
    endpoint: apiEndpoint + 'run/analyzer',
    scopes: {
      write: [appIdUri + 'RunAnalyzerService.Write']
    }
  },
  apiRunReporterService: {
    endpoint: apiEndpoint + 'run/reporter',
    scopes: {
      write: [appIdUri + 'RunReporterService.Write']
    }
  },
  apiUpdateDataService: {
    endpoint: apiEndpoint + 'data',
    scopes: {
      read: [appIdUri + 'UpdateDataService.Read'],
      write: [appIdUri + 'UpdateDataService.Write'],
      delete: [appIdUri + 'UpdateDataService.Delete']
    }
  },
  
  apiSSEService: {
    endpoint: apiEndpoint + 'sse',
    scopes: {
      read: [appIdUri + 'SSEService.Read']
    }
  }
}

/**
 * Configuration object for login request.
 * 
 * @constant
 * @type {RedirectRequest}
 * @property {string[]} scopes - The scopes to request during login. 
 *                               "openid" and "offline_access" are included by default.
 * @property {string} prompt - The prompt behavior for the login request. 
 *                             An empty string indicates the default behavior.
 */
export const loginRequest: RedirectRequest = {
  scopes: [ "openid", "offline_access" ],
  prompt: "",
}
