// ToastService.tsx
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'


/**
* Displays a toast message based on the SSE message status.
* @param sseMessage - The SSE message object.
*/
export const toaster = (sseMessage: any): void => {
    let message = `Experiment ${sseMessage.experimentid} has status: ${sseMessage.status}.`
    message = sseMessage.message ? message + ` ${sseMessage.message }` : message
    toast.info(
        <div><Link to={'/fileview/'}>{message}</Link></div>
    )
}