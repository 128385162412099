/* eslint-disable react/react-in-jsx-scope */
import { AuthenticatedTemplate } from '@azure/msal-react'
import { FileShareContent } from '../components/FileShare'
import { FileUploadContent } from '../components/FileUpload'

 /* The `MsalAuthenticationTemplate` component will render its children if a user is authenticated
 * or attempt to sign a user in. Just provide it with the interaction type you would like to use
 * (redirect or popup) and optionally a request object to be passed to the login API, a component to display while
 * authentication is in progress or a component to display if an error occurs. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
export const FileSharePage = (): JSX.Element => {

  return (
        <AuthenticatedTemplate>
            <div className='componentsStyle'>
                <br />
                    <h2>
                        <center>Experiments on your file share</center>
                    </h2>
                <br />
                <div className='sameRow'>
                    <div className="fileShare"> 
                        <FileShareContent />
                    </div> 
                    <div className="fileUpload"> 
                        <FileUploadContent />
                    </div> 
                </div>
            </div>
        </AuthenticatedTemplate>
  )
}
