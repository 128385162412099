/* eslint-disable react/react-in-jsx-scope */
import { AuthenticatedTemplate } from '@azure/msal-react'
import "@ag-grid-community/styles/ag-theme-quartz.css"; // Theme
import "@ag-grid-community/styles/ag-grid.css"; // Core CSS

/***
 * Component to detail ID token claims with a description for each claim. For more details on ID token claims, please check the following links:
 * ID token Claims: https://docs.microsoft.com/en-us/azure/active-directory/develop/id-tokens#claims-in-an-id-token
 * Optional Claims:  https://docs.microsoft.com/en-us/azure/active-directory/develop/active-directory-optional-claims#v10-and-v20-optional-claims-set
 */
export const HomePage = (): JSX.Element => {

return ( 
    <div>
        <AuthenticatedTemplate>
            <div className="center">

                        <br />
                             <div className="welcome-text">
                                <h2 id="welcome-heading">
                                    <center>Welcome to the Oncobit Customer Portal</center>
                                </h2>
                          
                                <center>
                                <br />
                                <br />
                                Please navigate to <a href="/fileview">File Share</a> to upload a file, to analyze your experiment data, or to download your reports.
                                </center>
                                <br />
                                <br />
                                <center>
                                    <strong>Thank you for using Oncobit PM Analyzer!</strong>
                                </center>
                            </div>
                        <br />
                    </div>
        </AuthenticatedTemplate>
    </div>
)
}
