/**
 * Creates a debounced function that delays invoking the provided function until after the specified delay.
 * 
 * @param func - The function to debounce.
 * @param delay - The number of milliseconds to delay.
 * @returns A new debounced function.
 */
export function debounce(func: any, delay: number) {
  let debounceTimer: NodeJS.Timeout;
  return function(this: any, ...args: any) {
    const context = this;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(context, args), delay);
  }
}