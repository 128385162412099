// FileShareContext.js
import React, { createContext, useState, useMemo, useCallback, useRef, useEffect, useContext } from 'react';
import { IExpData } from '../types/types';
import { DataContext } from './DataContext';
import { DownloadButton } from "../components/CustomButtons";
import { AgGridReact } from '@ag-grid-community/react';
import { ColDef, ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
ModuleRegistry.registerModules([ClientSideRowModelModule]);

export interface FileShareContextType {
  colDefs: ColDef[];
  setColDefs: React.Dispatch<React.SetStateAction<ColDef[]>>;
  rowData: IExpData[] | undefined;
  setRowData: React.Dispatch<React.SetStateAction<IExpData[] | undefined>>;
  selection: IExpData[];
  setSelection: React.Dispatch<React.SetStateAction<IExpData[]>>;
  messages: string[];
  setMessages: React.Dispatch<React.SetStateAction<string[]>>;
  experimentMode: boolean;
  setExperimentMode: React.Dispatch<React.SetStateAction<boolean>>;
  language: string;
  setLanguage: React.Dispatch<React.SetStateAction<string>>;
  printMessage: (newMessage: string) => void;
  gridRef: React.RefObject<any>;
  containerStyle: React.CSSProperties;
  defaultColDef: ColDef;
  feSettings: { useLims: boolean; useExpMode: boolean };
}

export const FileShareContext = createContext<FileShareContextType>({
  colDefs: [],
  setColDefs: () => { },
  rowData: undefined,
  setRowData: () => { },
  selection: [],
  setSelection: () => { },
  messages: [],
  setMessages: () => { },
  experimentMode: false,
  setExperimentMode: () => { },
  language: 'german',
  setLanguage: () => { },
  printMessage: () => { },
  gridRef: { current: null },
  containerStyle: { width: '100%', height: '100%' },
  defaultColDef: {
    flex: 1,
    filter: true,
    editable: false,
    suppressMovable: true,
    filterParams: { newRowsAction: 'keep' },
  },
  feSettings: { useLims: false, useExpMode: false },
});

/**
 * FileShareProvider component that provides context for file sharing functionalities.
 * 
 * @param {Object} props - The properties object.
 * @param {React.ReactNode} props.children - The child components to be wrapped by the provider.
 * 
 * @returns {JSX.Element} The FileShareContext provider with the necessary values and functions.
 * 
 * @context {DataContext} - Consumes DataContext to get fileShareData, feSettings, and updateFiles.
 * 
 * @state {Array<ColDef>} colDefs - State to manage column definitions for the grid.
 * @state {Array<IExpData>} selection - State to manage selected experiment data.
 * @state {Array<string>} messages - State to manage messages for user notifications.
 * @state {Array<IExpData>} rowData - State to manage row data for the grid.
 * @state {boolean} experimentMode - State to manage the experiment mode.
 * @state {string} language - State to manage the language setting.
 * 
 * @function printMessage - Function to print a new message and remove it after 5 seconds.
 * 
 * @effect Updates files and sets row data on mount and every 30 seconds.
 * @effect Updates the upload history element with messages.
 * @effect Sets column definitions for the grid.
 * 
 * @constant {Object} defaultColDef - Default column definitions for the grid.
 * 
 * @returns {JSX.Element} The FileShareContext provider with the necessary values and functions.
 */
export const FileShareProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { fileShareData, feSettings, updateFiles } = useContext(DataContext);

  const gridRef = useRef<AgGridReact>(null);
  const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
  const [colDefs, setColDefs] = useState<ColDef[]>([])
  const [selection, setSelection] = useState<IExpData[]>([])
  const [messages, setMessages] = useState<string[]>([])
  const [rowData, setRowData] = useState<IExpData[]>()
  const [experimentMode, setExperimentMode] = useState(false);
  const [language, setLanguage] = useState('german');

  const printMessage = useCallback((newMessage: string) => {
    setMessages(prevMessages => [...prevMessages, newMessage]);
    setTimeout(() => {
      setMessages(prevMessages => prevMessages.filter(message => message !== newMessage));
    }, 5000);
  }, []);

  useEffect(() => {
    updateFiles();
    setRowData(fileShareData);
    const interval = setInterval(updateFiles, 30 * 1000);
    return () => clearInterval(interval);
  }, [updateFiles, fileShareData]);

  useEffect(() => {
    const element = document.getElementById("uploadHistory");
    if (element) {
      element.innerHTML = messages.join('<br />');
    }
  }, [messages]);

  useEffect(() => {
    setColDefs([
      {
        field: "experiment_name",
        headerName: "Experiment ID",
        cellDataType: "text",
        width: 150,
        lockPosition: "left",
        headerCheckboxSelection: true,
        checkboxSelection: true,
        showDisabledCheckboxes: true,
      },
      {
        field: "status",
        headerName: "Status",
        cellDataType: "text",
        width: 75,
      },
      {
        field: "date_upload",
        headerName: "Date last uploaded",
        valueFormatter: (params: any) => {
          if (params.value === undefined || params.data.modified > params.data.run) {
            return "";
          }
          const date = new Date(params.value);
          const formattedDate = date.toLocaleTimeString([], { year: "2-digit", month: "2-digit", day: "2-digit" });
          return formattedDate;
        },
        cellDataType: "text",
        width: 75,
        sort: "desc"
      },
      {
        field: "tenant_id",
        headerName: "User",
        cellDataType: "text",
        width: 75
      },
      {
        field: "date_run_analyzer",
        headerName: "Date Analyzer",
        valueFormatter: (params: any) => {
          if (params.value === undefined || params.data.modified > params.data.run) {
            return "";
          }
          const date = new Date(params.value);
          const formattedDate = date.toLocaleTimeString([], { year: "2-digit", month: "2-digit", day: "2-digit" });
          return formattedDate;
        },
        cellDataType: "text",
        width: 75,
      },
      {
        field: "date_run_reporter",
        headerName: "Date Reporter",
        valueFormatter: (params: any) => {
          if (params.value === undefined || params.data.modified > params.data.run) {
            return "";
          }
          const date = new Date(params.value);
          const formattedDate = date.toLocaleTimeString([], { year: "2-digit", month: "2-digit", day: "2-digit" });
          return formattedDate;
        },
        cellDataType: "text",
        width: 75,
      },
      {
        field: "result_folder_analyzer",
        headerName: "PM-Results",
        cellRenderer: DownloadButton,
        onCellClicked: (params) => {
          if (params.value.length > 0) {
            printMessage("Download Analyzer results for ID: " + params.data.experiment_name);
          }
        },
        maxWidth: 150
      },
      {
        field: "result_folder_reporter",
        headerName: "Patient-Reports",
        cellRenderer: DownloadButton,
        onCellClicked: (params) => {
          if (params.value.length > 0) {
            printMessage("Download Report for ID: " + params.data.experiment_name);
          }
        },
        maxWidth: 200,
        resizable: false,
        lockPosition: "right"
      },
    ]);
  }, [printMessage]);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      filter: true,
      editable: false,
      suppressMovable: true,
      filterParams: { newRowsAction: 'keep' }
    };
  }, []);

  return (
    <FileShareContext.Provider value={{
      colDefs, setColDefs,
      rowData, setRowData,
      selection, setSelection,
      messages, setMessages,
      experimentMode, setExperimentMode,
      language, setLanguage,
      printMessage,
      gridRef,
      containerStyle,
      defaultColDef,
      feSettings,
    }}>
      {children}
    </FileShareContext.Provider>
  );
};