import { type ContainerClient } from '@azure/storage-blob'
import { type FileWithPath } from 'react-dropzone'

const blobOptions = {
  maxSingleShotSize: 1 * 1000 * 1000,
  blockSize: 1000 * 100
}

/**
 * Deletes blobs from an Azure Blob Storage container based on a list of keys.
 *
 * @param containerClient - The Azure Blob Storage container client.
 * @param keyList - An array of string keys. Blobs whose names start with any of these keys will be deleted.
 * @throws Will throw an error if a blob deletion fails.

 */
export const deleteBlobs = async (containerClient: ContainerClient, keyList: string[]) => {

  const blobList = []
  for await (const blob of containerClient.listBlobsFlat()) {
    blobList.push(blob.name as string)
  }

  console.log(blobList)

  for (const key of keyList) {
    for (const blob of blobList) {

      console.log("Key:", key)
      console.log("Blob:", blob)
      
      if(blob.startsWith(key)){
        const blockBlobClient = containerClient.getBlockBlobClient(blob)
        const res = await blockBlobClient.delete()
        console.log(res)
        if (res._response.status !== 202) {
          throw new Error(`Failed to delete file: ${blob}! Response status ${res._response.status}`)
        }
      }
    }
  }
}

/**
 * Uploads files to a specified container in Azure Blob Storage.
 *
 * @param folderFiles - A map where the key is the folder name and the value is an ExperimentFolder object containing files to be uploaded.
 * @param containerClient - The Azure Blob Storage container client used to interact with the storage container.
 * @returns A promise that resolves when all files have been uploaded.
 * @throws Will throw an error if any file upload fails or if there is an error during the upload process.
 *
 * The function iterates over the provided folderFiles map, and for each folder:
 * - If the `overwrite` flag is set to true, it deletes the existing folder in the container.
 * - It then uploads each file in the folder to the container.
 * - If any upload fails, it throws an error with the corresponding file name and response status.
 */
export const uploadFiles = async (folderFiles: Map<string, ExperimentFolder>, containerClient: ContainerClient): Promise<void> => {
  try {
    for (const [key, value] of folderFiles) {

      if(value.overwrite){
        // Delete existing folder if exists
        console.log('Trying to overwrite ', key)
        await deleteBlobs(containerClient, [key])
      }

      for (const file of value) {
        const currentFile = file.name
        let uploadName = file.path !== undefined ? file.path : file.name
        uploadName = uploadName.startsWith('/') ? uploadName.slice(1) : uploadName

        const blockBlobClient = containerClient.getBlockBlobClient(uploadName)

        const res = await blockBlobClient.uploadData(file, blobOptions)
        if (res._response.status !== 201) {
          throw new Error(`Upload failed for file: ${currentFile}! Response status ${res._response.status}`)
        }
      }
      console.log('Upload successful! Folder: ', key)
    }
  } catch (error) {
    throw new Error('Error uploading files', { cause: error })
  }
}

/**
 * Represents an experiment folder containing files with specific naming conventions.
 * 
 * @class ExperimentFolder
 * @property {string} _experimentName - The name of the experiment.
 * @property {FileWithPath[]} _files - The files associated with the experiment.
 * @property {string} baseFormat - The base format for file names.
 * @property {RegExp} _regex - The regular expression used to validate file names.
 * @property {boolean} _overwrite - Flag indicating whether to overwrite existing files.
 * 
 * @constructor
 * @param {string} name - The name of the experiment.
 * @param {FileWithPath[]} files - The files associated with the experiment.
 * 
 * @method get regex - Gets the regular expression used to validate file names.
 * @method set regex - Sets the regular expression used to validate file names.
 * @method get experimentName - Gets the name of the experiment.
 * @method get overwrite - Gets the overwrite flag.
 * @method get files - Gets a copy of the files associated with the experiment.
 * @method set experimentName - Sets the name of the experiment.
 * @method set overwrite - Sets the overwrite flag.
 * @method set files - Sets the files associated with the experiment after filtering.
 * @method checkName - Checks if a file name is valid based on the regular expression.
 * @method filterFiles - Filters the files based on the regular expression.
 * @method [Symbol.iterator] - Iterates over the files in the experiment folder.
 */
export class ExperimentFolder {
  private _experimentName: string = "";
  private _files: FileWithPath[] = [];
  private readonly baseFormat: string = '(_([0-9]{8})_([0-9]{6})_([0-9]{3}))?(_[A-Z]{1}[0-9]{2}_Amplitude)?(_metadata)?.(csv|xlsx)';
  private _regex: RegExp;
  private _overwrite: boolean = true;

  constructor(name: string, files: FileWithPath[]) {
    this.experimentName = name;
    this._regex = new RegExp(`^${name}${this.baseFormat}$`);
    this.files = files;
  }
  
  // private get regex(): RegExp {
  //   return this._regex;
  // }

  // private set regex(value: RegExp | string) {
  //   if (value instanceof RegExp) {
  //     this._regex = value;
  //   } else {
  //     try {
  //       this._regex = new RegExp(value);
  //     } catch {
  //       throw new Error('Invalid Regular expression');
  //     }
  //   }
  // }

  // Getters
  get experimentName(): string {
    return this._experimentName;
  }

  get overwrite(): boolean {
    return this._overwrite;
  }

  get files(): FileWithPath[] {
    return this._files.slice(); // Shallow copy to prevent direct modification
  }

  // Setters
  set experimentName(value: string) {
    if (value.trim().length > 0) {
      this._experimentName = value;
    } else {
      throw new Error('Invalid ExperimentFolder name');
    }
  }

  set overwrite(value: boolean) {
    this._overwrite = value;
  }

  set files(value: FileWithPath[]) {
    if (Array.isArray(value) && value.every((file) => 'name' in file && 'path' in file)) {
      this._files = this.filterFiles(value);
    } else {
      throw new Error('Invalid files array provided to ExperimentFolder.');
    }
  }

  private checkName(fileName: string): boolean {
    const validName = this._regex.test(fileName);
    return validName;
  }

  private filterFiles(files: FileWithPath[]): FileWithPath[] {
    const filteredFiles = files.filter((file) => this.checkName(file.name));

    if (filteredFiles.length === 0) {
      throw new Error('No valid files after filtering.');
    }
    return filteredFiles;
  }

  *[Symbol.iterator](): IterableIterator<FileWithPath> {
    yield* this._files;
  }
}

function getFolderName (path: string): string {
  path = path.startsWith('/') ? path.slice(1) : path
  if (path.includes('/')) {
    return path.split('/')[0]
  } else {
    throw new Error('Invalid path')
  }
}

/**
 * Aggregates a list of files into a map of experiment folders.
 *
 * @param files - An array of `FileWithPath` objects to be aggregated.
 * @returns A `Map` where the keys are folder names and the values are `ExperimentFolder` objects containing the files in those folders.
 */
export const aggregateFiles = (files: FileWithPath[]): Map<string, ExperimentFolder> => {
  const aggregatedData = new Map<string, ExperimentFolder>()
  const currentObject = new Map<string, FileWithPath[]>()

  // Aggregate files by folder
  for (const file of files) {
    if (file.path != null) {
      const relativePath = getFolderName(file.path) // Use optional chaining to handle undefined file.path
      const existingList = currentObject.get(relativePath)
      if ((existingList != null) && Array.isArray(existingList)) {
        existingList.push(file)
        currentObject.set(relativePath, existingList)
      } else {
        currentObject.set(relativePath, [file])
      }
    }
  }


  // Create ExperimentFolder objects
  for (const [key, value] of currentObject) {
    aggregatedData.set(key, new ExperimentFolder(key, value))
  }

  return aggregatedData
}
