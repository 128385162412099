export interface SelectionProps {
  items: string[]
  selectedItems: string[]
  onSelectItem: (item: string) => void
  onSelectAll: (items: string[]) => void
  onDeselectAll: () => void
  onClicker: () => void
  buttonName: string
};

export interface IExpData {
  experiment_id: number;
  experiment_name: string;
  tenant_id: string;
  date_upload: Date;
  status: string | undefined;
  message: string | undefined;
  date_run_analyzer: Date | undefined;
  status_analyzer: string | undefined;
  message_analyzer: string | undefined;
  result_folder_analyzer: string | undefined;
  date_run_reporter: Date | undefined;
  message_reporter: string | undefined;
  result_folder_reporter: string | undefined;
  status_reporter: string | undefined;
}

export interface IUXData {
  data: IExpData[];
  permissions: {
    exp_mode: boolean;
    lims: boolean;
  };
}

export function isIExpData(obj: any): obj is IExpData {

  return (
    obj !== null &&
    typeof obj === 'object' &&
    typeof obj.experiment_id === 'number' &&
    typeof obj.experiment_name === 'string' &&
    typeof obj.tenant_id === 'string' &&
    new Date(obj.date_upload) instanceof Date &&
    (typeof obj.status === 'string' || obj.status === undefined) &&
    (typeof obj.message === 'string' || obj.message === undefined) &&
    (obj.date_run_analyzer === undefined || new Date(obj.date_run_analyzer) instanceof Date) &&
    (typeof obj.status_analyzer === 'string' || obj.status_analyzer === undefined) &&
    (typeof obj.message_analyzer === 'string' || obj.message_analyzer === undefined) &&
    (typeof obj.result_folder_analyzer === 'string' || obj.result_folder_analyzer === undefined) &&
    (obj.date_run_reporter === undefined || new Date(obj.date_run_reporter) instanceof Date) &&
    (typeof obj.message_reporter === 'string' || obj.message_reporter === undefined) &&
    (typeof obj.result_folder_reporter === 'string' || obj.result_folder_reporter === undefined) &&
    (typeof obj.status_reporter === 'string' || obj.status_reporter === undefined)
  );
}

export function isIUXData(obj: any): obj is IUXData {
   return (
    obj !== null &&
    typeof obj === 'object' &&
    Array.isArray(obj.data) &&
    obj.data.every(isIExpData) && // Checks each item in `data` to see if it conforms to `IExpData`
    obj.permissions !== null &&
    typeof obj.permissions === 'object' &&
    typeof obj.permissions.exp_mode === 'boolean' &&
    typeof obj.permissions.lims === 'boolean'
  );
}