/**
 * Initializes the MSAL instance and sets up event callbacks for authentication events.
 * 
 * The MSAL instance is created outside of the component tree to prevent re-instantiation on re-renders.
 * 
 * The following steps are performed:
 * 1. Initialize the MSAL instance.
 * 2. Set the active account to the first account if no account is active on page load.
 * 3. Enable account storage events to update account state if a user signs in from another tab or window.
 * 4. Add event callbacks for login success, token acquisition success, and silent SSO success events.
 * 5. Set the active account based on the event payload.
 * 6. Render the React application using ReactDOM.
 * 
 * @see {@link https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md}
 */
import { PublicClientApplication, EventType, type EventMessage, type AuthenticationResult } from '@azure/msal-browser'
import { BrowserRouter } from 'react-router-dom'
import ReactDOM from 'react-dom/client'
import { msalConfig } from './authConfig'
import App from './App'
import 'bootstrap/dist/css/bootstrap.min.css'
import './styles/index.css'


export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.initialize().then(() => {
  // Default to using the first account if no account is active on page load
  if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  // Optional - This will update account state if a user signs in from another tab or window
  msalInstance.enableAccountStorageEvents();

  msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS
      ||
      event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
      ||
      event.eventType === EventType.SSO_SILENT_SUCCESS
    ) {
      console.log('Login Success!')

      const account = (event.payload as AuthenticationResult).account;
      msalInstance.setActiveAccount(account);
    }
  });

  const container = document.getElementById("root")!;
  const root = ReactDOM.createRoot(container);

  root.render(
    <BrowserRouter>
        <App instance={msalInstance} />   
    </BrowserRouter>

  );
});