import { AuthError, type AuthenticationResult } from '@azure/msal-browser'
import { useState, useCallback, useRef } from 'react'
import { useAuth } from '../contexts/AuthContext'

/**
 * Custom hook to call a web API using bearer token obtained from MSAL
 * @returns An object containing the result, error, abortController, and execute function
 */
const useFetchWithMsal = (): { 
  result: AuthenticationResult | null
  error: Error | null
  abortControllerRef: React.MutableRefObject<AbortController | undefined>
  execute: (method: string, endpoint: URL, data?: any, stream?: boolean) => Promise<Response | undefined>
} => {
  //const { instance } = useMsal()
  const { result, error: msalError } = useAuth();
  const [error, setError] = useState<Error | null>(null)
  const abortController = useRef<AbortController>()
  
  /**
   * Execute a fetch request with the given options
   * @param {string} method - The HTTP method (GET, POST, PUT, DELETE)
   * @param {URL} endpoint - The endpoint to call
   * @param {Record<string, string[]>} data - The data to send (optional)
   * @returns The JSON response
   */
  const execute = async (method: string, endpoint: URL, data?: any, stream?: boolean): Promise<Response | undefined> => {
  
      abortController.current = new AbortController();
  
      if (msalError instanceof AuthError) {
        setError(msalError)
        return
      }
  
      try {
  
        if (result?.accessToken == null) {
          throw new AuthError('No MSAL access token available')
        }
  
        const headers = new Headers()
        const bearer = `Bearer ${result.accessToken}`
        headers.append('Authorization', bearer)
  
        const options: RequestInit = {
          method,
          signal: abortController.current.signal
        }
  
        
        if ((method === 'POST' || method === 'DELETE') && data != null){
            headers.append('Accept', 'application/json')
            headers.append('Content-Type', 'application/json')
            options.body = JSON.stringify(data)
        } else if(method === 'GET' && stream){
          headers.append('Accept', 'text/event-stream')
          headers.append('Content-Type', 'text/event-stream')
        }
  
        options.headers = headers
  
        const response: Response = await fetch(endpoint, options)


        if(response.status === 401){
          throw new AuthError('Unauthorized')
        } else if (response.status === 503) {
          window.alert('The service is currently unavailable. Please try again later or contact the Oncobit Support.');
        }

        return response
        
      } catch (e) {
        console.debug(e)
        setError(e as Error);
        return Promise.reject(e);
      }
    }

  return {
    result,
    error,
    abortControllerRef: abortController,
    execute: useCallback(execute, [result, msalError, abortController]), // to avoid infinite calls when inside a `useEffect`
  }
}

export default useFetchWithMsal
