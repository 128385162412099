// NotFound.js
/**
 * NotFound component renders a 404 error message.
 * 
 * This component displays a message indicating that the requested page
 * could not be found. It includes a heading with a "404 - Not Found" message
 * and a paragraph with a brief explanation.
 * 
 * @returns {JSX.Element} A JSX element containing the 404 error message.
 */
function NotFound(): JSX.Element {
  return (
    <div>
      <h1>404 - Not Found</h1>
      <p>Sorry, the page you are looking for does not exist.</p>
    </div>
  );
}
export default NotFound;
