// SSEContext.ts
import React, { createContext, useCallback, useContext, useState, useMemo, useRef } from 'react';
import { toaster } from '../utils/ToastService';
import useFetchWithMsal from '../hooks/useFetchWithMsal'
import { protectedResources } from '../authConfig'
import { DataContext } from './DataContext';
import 'react-toastify/dist/ReactToastify.css';

/**
 * Context for managing Server-Sent Events (SSE) connection state.
 * 
 * @property {boolean} isConnected - Indicates if the SSE connection is established.
 * @property {boolean} isConnecting - Indicates if the SSE connection is in the process of being established.
 * @property {Error | null} error - Holds any error that occurred during the connection process.
 * @property {boolean} abort - Indicates if the connection process should be aborted.
 * @property {() => void} connect - Function to initiate the SSE connection.
 * @property {() => void} disconnect - Function to terminate the SSE connection.
 */
export const SSEContext = createContext<{ isConnected: boolean; isConnecting: boolean; error: Error | null; abort: boolean; connect: () => void; disconnect: () => void }>({
  isConnected: false,
  isConnecting: false,
  error: null,
  abort: false,
  connect: () => {},
  disconnect: () => {},
});

/**
 * SSEProvider component that provides Server-Sent Events (SSE) functionality to its children.
 * It manages the connection to the SSE server, handles incoming messages, and provides context values
 * to indicate the connection status and control the connection.
 *
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The child components that will be wrapped by the provider.
 *
 * @returns {JSX.Element} The SSEContext.Provider component with the provided context values.
 *
 * @context
 * - `isConnected` (boolean): Indicates if the SSE connection is currently established.
 * - `isConnecting` (boolean): Indicates if the SSE connection is in the process of being established.
 * - `error` (any): Holds any error that occurred during the connection process.
 * - `abort` (boolean): Indicates if the connection has been aborted.
 * - `connect` (function): Function to initiate the SSE connection.
 * - `disconnect` (function): Function to terminate the SSE connection.
 */
export const SSEProvider = ({ children }: { children: React.ReactNode }) => {

  const { updateFiles } = useContext(DataContext);

  const { execute, abortControllerRef, error } = useFetchWithMsal()

  const heartbeatTimeoutRef = useRef<null | NodeJS.Timeout>(null);
  const sseRef = useRef<ReadableStreamDefaultReader<Uint8Array> | null>(null);
  const [isConnected, setConnected] = useState<boolean>(false)
  const [abort, setAbort] = useState<boolean>(false)
  const [isConnecting, setIsConnecting] = useState<boolean>(false);

  /**
   * Resets the heartbeat timeout. If a previous timeout exists, it clears it and sets a new one.
   * If the heartbeat is not received within the specified timeout period, it logs a message and sets the abort state to true.
   *
   * @remarks
   * Adjust the timeout period based on your heartbeat interval.
   *
   * @returns {void} This function does not return a value.
   */
  const resetHeartbeat = useCallback((): void => {
    if (heartbeatTimeoutRef.current) {
      clearTimeout(heartbeatTimeoutRef.current);
    }
    heartbeatTimeoutRef.current = setTimeout(() => {
      console.log('Heartbeat not received, reconnecting...');
      setAbort(true);
    }, 15000); // Adjust the timeout period based on your heartbeat interval
  }, [setAbort]);

  /**
   * Handles incoming Server-Sent Events (SSE) messages.
   * 
   * This function processes the incoming SSE message, decodes it, and performs actions based on the message type.
   * It handles heartbeat messages by updating the last heartbeat timestamp and other messages by updating files and displaying a toaster notification.
   * 
   * @param {ReadableStreamReadResult<Uint8Array>} result - The result object containing the SSE message data.
   * @returns {Promise<void>} A promise that resolves when the message has been processed.
   * 
   * @throws Will log an error if the message cannot be parsed.
   */
  const handleSSEMessage = useCallback(async (result: ReadableStreamReadResult<Uint8Array>): Promise<void> => {
    const { value } = result;

    try {
      let data = new TextDecoder('utf-8').decode(value);

      console.log('Data received:', data)
  
      const parsedData = JSON.parse(data) as { type?: string, timestamp?: string };
      // Assuming heartbeat messages are identified by a type property
      if (parsedData.type && parsedData.type === 'heartbeat') {
        console.log('Heartbeat received:', parsedData.timestamp); // Log heartbeat with timestamp
        resetHeartbeat() // Update the last heartbeat timestamp
      } else if(parsedData.type && parsedData.type === 'message') {
        updateFiles()
        toaster(parsedData); // Handle other messages
      } else{
        console.log('Unknown message type:', parsedData);
      }
    } catch (error) {
      console.error('Error parsing SSE message:', error);
    }
  }, [resetHeartbeat, updateFiles])

  /**
   * Disconnects from the Server-Sent Events (SSE) connection.
   * 
   * This function logs a message indicating the disconnection process,
   * cancels the current SSE connection if it exists, and aborts the 
   * associated abort controller. After a delay of 5 seconds, it updates 
   * the state to reflect that the connection has been terminated and 
   * the abort flag has been reset.
   * 
   * @returns {Promise<void>} A promise that resolves when the disconnection process is complete.
   */
  const disconnect = useCallback(async (): Promise<void> => {
    console.log('Disconnecting from SSE...');
    if(sseRef.current && abortControllerRef.current){      
      sseRef.current.cancel();
      sseRef.current = null;
      abortControllerRef.current.abort();
    }
    setTimeout(() => {
      setConnected(false);
      setAbort(false);
    } , 5000)
  } ,[abortControllerRef])  
  

  /**
   * Makes a GET request to the server using server-sent events (SSE) and listens for incoming messages.
   * If a message is received, it is parsed and passed to the `toaster` function.
   * If an error occurs, the function will log the error and restart the stream after 1 second.
   */
  const connect = useCallback((): void => {
    if (isConnecting || isConnected) return;

    setIsConnecting(true); 

    console.log('Connecting to SSE...');

    execute('GET', new URL(protectedResources.apiSSEService.endpoint), undefined, true)
      .then(response => {
        // Get the readable stream from the response body
        if (response!.body !== null) {
          
          // Get the reader from the stream
          sseRef.current = response!.body.getReader()

          // Define a function to read each chunk
          const readChunk = (): void => {
            sseRef.current!.read().then(result => {
              if (!result.done) {
                handleSSEMessage(result);
                readChunk(); // Call readChunk again to read the next chunk
              } else {
                console.log('Stream finished')
              }
            })
            .catch(error => {
              console.error('Error reading chunk:', error);
              sseRef.current?.releaseLock();
            }).finally(() => {
              setTimeout(() => {
                setIsConnecting(false);
              } , 1000)
            });
          };
          // Start reading the first chunk
          setConnected(true);
          readChunk()
        }
      }).catch(error => {
        console.log('Error connecting to SSE:', error)
        disconnect()
      }).finally(() => {
        
        setTimeout(() => {
          setIsConnecting(false);
        } , 1000)
      });

  }, [disconnect, execute, handleSSEMessage, isConnected, isConnecting])

  const contextValue = useMemo(() => ({
    isConnected,
    isConnecting,
    error,
    abort,
    connect,
    disconnect
  }), [isConnected, isConnecting, error, abort, connect, disconnect]);
  
  return (
    <SSEContext.Provider value={contextValue}>
      {children}
    </SSEContext.Provider>
  );

};