import { Routes, Route } from 'react-router-dom'
import { FileSharePage } from '../pages/FileSharePage'
import { HomePage } from '../pages/HomePage'
import NotFound from '../pages/NotFound'
import { useEffect, useContext } from 'react'
import { SSEContext } from '../contexts/SSEContext'
import { AuthError } from '@azure/msal-browser'
import { Logout } from '../pages/Logout'

/**
 * The `Pages` component is responsible for rendering the main routes of the application.
 * It manages the connection to a Server-Sent Events (SSE) service and handles reconnection
 * logic and error handling.
 *
 * @returns {JSX.Element} The rendered routes of the application.
 *
 * @remarks
 * This component uses the `useContext` hook to access the SSE connection state from the `SSEContext`.
 * It attempts to connect to the SSE service if not already connected or in the process of connecting.
 * If the connection is aborted or an error occurs, it handles the error appropriately,
 * including retrying the connection or reloading the page in case of an authentication error.
 *
 * @component
 */
export const Pages = (): JSX.Element => {

      const { isConnected, isConnecting, error, abort, connect, disconnect } = useContext(SSEContext);

      useEffect(() => {
            if (!(isConnected || isConnecting)) {
                  const timeout = setTimeout(() => {
                        connect();
                  }, 1000); 
                  
                  return () => clearTimeout(timeout);
            }
      }, [isConnected, isConnecting, connect]);


      useEffect(() => {
            if (abort || (error instanceof DOMException && error.name === 'AbortError')) {
                  console.log('SSE connection aborted');
                  setTimeout(() => {
                        disconnect();
                  }, 1000)
            }

            if (error instanceof AuthError) {
                  window.location.reload();
            }
      }, [error, abort, disconnect]);

      return (
            <Routes>
                  <Route path="/fileview" element={<FileSharePage />} />
                  <Route path="/" element={<HomePage />} />
                  <Route path="/logout" element={<Logout />} />
                  <Route path="*" element={<NotFound />} />
            </Routes>
      )
}
