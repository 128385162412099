/* eslint-disable react/react-in-jsx-scope */
import React from 'react';
import { PublicClientApplication } from '@azure/msal-browser'
import PageLayout from './components/PageLayout'
import { Pages } from './components/Pages'
import './styles/App.css'
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js'
import { reactPlugin } from './utils/ApplicationInsightsService'
import { MsalProvider } from '@azure/msal-react'

/**
 * Main application component that sets up the context providers for App Insights and MSAL.
 *
 * @param {Object} props - The component props.
 * @param {PublicClientApplication} props.instance - The instance of the PublicClientApplication for MSAL.
 * @returns {JSX.Element} The rendered application component.
 */
const App = ({ instance }: { instance: PublicClientApplication }): JSX.Element => {  

  return (
      <AppInsightsContext.Provider value={reactPlugin}>
        <MsalProvider instance={instance}>
            <PageLayout>
              <Pages/>
            </PageLayout>
        </MsalProvider>
      </AppInsightsContext.Provider>
  )
}

export default React.memo(App);

