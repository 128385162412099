
  import React, { createContext, useState, useCallback, useRef, useMemo } from 'react';
  import { protectedResources } from '../authConfig'
  import useFetchWithMsal from '../hooks/useFetchWithMsal'
  import { ContainerClient } from '@azure/storage-blob';
  import { IExpData, IUXData, isIUXData } from '../types/types';
  import hash from 'object-hash'
  import { deleteBlobs } from '../utils/UploadUtils';
  
  const DataContext = createContext
  <{ 
    fileShareData: IExpData[]; 
    feSettings: {useLims: boolean, useExpMode: boolean}; 
    getContainerClient: (endpoint: string) => Promise<ContainerClient | undefined> ; 
    runAnalyzer: (experimentIds: number[], mode: string) => Promise<Response | undefined> ; 
    runReporter: (experimentIds: number[], language?: string) => Promise<Response | undefined> ;
    updateData: (experimentNames: string[]) => Promise<Response | undefined>;
    deleteData: (experiments: Map<number, string>) => Promise<number>;
    updateFiles: () => Promise<void>;
    connectionStatus: string;
  }>(
    { 
      fileShareData: [], 
      feSettings: {useLims: false, useExpMode: false},
      getContainerClient: async () => { return undefined },
      runAnalyzer: async () => { return undefined },
      runReporter: async () => { return undefined },
      updateData: async () => { return undefined },
      deleteData: async () => { return 1},
      updateFiles: async () => { return undefined },
      connectionStatus: 'disconnected'
    });
  
  /**
   * DataStorageProvider is a React memoized component that provides data storage and management functionalities
   * to its children components. It uses various hooks and callbacks to fetch, update, delete, and manage data
   * from a backend service.
   *
   * @component
   * @param {Object} props - The properties object.
   * @param {React.ReactNode} props.children - The child components that will be wrapped by this provider.
   *
   * @returns {JSX.Element} The DataStorageProvider component that wraps its children with the DataContext provider.
   *
   * @example
   * ```tsx
   * <DataStorageProvider>
   *   <YourComponent />
   * </DataStorageProvider>
   * ```
   *
   * @remarks
   * This component uses the `useFetchWithMsal` hook to execute HTTP requests with MSAL authentication.
   * It provides various functions to interact with the backend, including fetching data, updating data,
   * deleting data, running analyzers, and retrieving SAS tokens for Azure Blob Storage.
   *
   * The context value provided by this component includes:
   * - `fileShareData`: An array of file share data.
   * - `feSettings`: Frontend settings including `useLims` and `useExpMode`.
   * - `getContainerClient`: A function to get a ContainerClient instance using a SAS token.
   * - `runAnalyzer`: A function to execute the PM Analyzer.
   * - `runReporter`: A function to execute the PM Reporter.
   * - `updateData`: A function to update data for a given table.
   * - `deleteData`: A function to delete data for a given table.
   * - `updateFiles`: A function to update files by fetching data and updating the state.
   * - `connectionStatus`: The current connection status.
   */
  const DataStorageProvider = React.memo(({ children }: { children: React.ReactNode }) => {
   
    const { execute } = useFetchWithMsal()
  
    const [feSettings, setFeSettings] = useState<{useLims: boolean, useExpMode: boolean}>({useLims: false, useExpMode: false})
    let topLayerHash = useRef<string>('')
    const  [connectionStatus, setConnectionStatus] = useState<string>('disconnected')
    const [fileShareData, setFileShareData] = useState<IExpData[]>([])
  
    /**
     * Updates the data for a given table with optional selected items.
     * @param experimentNames - Array of selected experiment names.
     * @returns A Promise that resolves to the updated data.
     */
    const updateData = useCallback(async (experimentNames: string[]) => {
      // Fetch data
      console.log("Updating data")
      return await execute("POST", new URL(protectedResources.apiUpdateDataService.endpoint), { experimentNames: experimentNames }).then((response) => {
        return response
      })
    }, [execute])
  

    /**
     * Retrieves a SAS (Shared Access Signature) token from the specified endpoint.
     *
     * @param {string} endpoint - The endpoint URL to fetch the SAS token from.
     * @returns {Promise<string>} A promise that resolves to the SAS token as a string.
     * @throws {Error} Throws an error if the SAS token retrieval fails due to authentication, network issues, or if the token is undefined.
     */
    const getSas = useCallback(async (endpoint: string): Promise<string> => {
        return execute('GET', new URL(endpoint)).then(async (response) => {
          if(response !== undefined) {
            const data = await response.json();
            const sasToken = data.sasUrl as string;

            if(sasToken === undefined) throw new Error('SASRetrievalError');

            return sasToken
          }else {
            throw new Error('SASRetrievalError');
          }
        }).catch((error) => {
          if (error instanceof Error && (error.name === 'AuthError' || error.name === 'NetworkError' || error.name === 'AbortError' || error.message === 'SASRetrievalError')) {
              throw new Error('SASRetrievalError', { cause: error });
          } else {
            console.error(error)
            throw error
          }
        })
    }, [execute])
  
  
  
    /**
     * Retrieves a ContainerClient instance using a SAS token from the specified endpoint.
     *
     * @param {string} endpoint - The endpoint from which to retrieve the SAS token.
     * @returns {Promise<ContainerClient | undefined>} A promise that resolves to a ContainerClient instance or undefined if there is an error retrieving the SAS token.
     *
     * @throws {Error} Throws an error with the message 'ContainerClientError' if an error other than 'SASRetrievalError' occurs.
     */
    const getContainerClient = useCallback(async(endpoint: string): Promise<ContainerClient | undefined> => {
      return getSas(endpoint).then((token: string) => {
        return new ContainerClient(token)
      }).catch((error) => {
        if (error instanceof Error && error.message === 'SASRetrievalError') {
          console.debug(error)
          return undefined
        } else{
          throw new Error('ContainerClientError', { cause: error });
        }
      })
    }, [getSas])
  

     /**
     * Delete data for a given table with optional selected items.
     * @param experiments - Array of selected experiment IDs.
     * @returns A Promise that resolves to the updated data.
     */
      const deleteData = useCallback(async (experiments: Map<number, string>) => {
  
        // Fetch data
        console.log("Deleting data")

        try {
          const containerClient = await getContainerClient(protectedResources.apiUploadService.endpoint)

          if(containerClient === undefined) return 1

          await deleteBlobs(containerClient, [...experiments.values()])
  
          const response = await execute("DELETE", new URL(protectedResources.apiUpdateDataService.endpoint), { experimentIds: [...experiments.keys()] })
          if(response?.status === 200) return 0
          else return 1
        } catch (error) {
          if (error instanceof Error && error.message === 'SASRetrievalError') {
            console.debug('Error getting SAS token:', error.message);
            return 1;
          } else if (error instanceof Error && error.name === 'ContainerClientError') {
            console.debug('Error getting container client:', error.name);
            return 1;
          } else {
            throw error
          }
        }
  
      }, [execute, getContainerClient])
    
  
      /**
     * Updates the data for a given table with optional selected items.
     * @returns A Promise that resolves to the updated data.
     */
      const getData = useCallback(async (): Promise<IUXData> => {
        
        // Fetch data
        const res = await execute("GET", new URL(protectedResources.apiUpdateDataService.endpoint)).then((response) => {
          return response?.json();
        }) as IUXData;

        if (!isIUXData(res)) {
          throw new Error('DataRetrievalError');
        }

        return res;
      }, [execute]);   //    Error getting files: TypeError: data.permissions is undefined

  
    /**
     * Executes the PM Analyzer with the given experiment IDs and mode.
     *
     * @param {number[]} experimentIds - An array of experiment IDs to be analyzed.
     * @param {string} mode - The mode in which the analyzer should run.
     * @returns {Promise<any>} A promise that resolves to the response from the PM Analyzer backend.
     */
    const runAnalyzer = useCallback(async (experimentIds: number[], mode: string) => {
      // Fetch call
      return await execute('POST', new URL(protectedResources.apiRunAnalyzerService.endpoint), { experimentIds: experimentIds, workflow: "pmanalyzer", mode: mode}).then(async (response) => {
        console.log("PM Analyzer backend response", response)
        return response
      })
    }, [execute])
  
    /**
     * Executes the PM Reporter workflow for the given experiment IDs.
     *
     * @param {number[]} experimentIds - An array of experiment IDs to run the reporter on.
     * @param {string} [language] - Optional language parameter for the reporter.
     * @returns {Promise<any>} A promise that resolves to the response from the PM Reporter backend.
     */
    const runReporter = useCallback(async (experimentIds: number[], language?: string) => {
      // Fetch call
  
      return await execute('POST', new URL(protectedResources.apiRunReporterService.endpoint), { experimentIds: experimentIds, workflow: "pmreporter", language: language }).then((response) => {
        console.log("PM Reporter backend response", response)
        return response
      })
      
    }, [execute])
  
    /**
     * Updates the files by fetching data and updating the state accordingly.
     * 
     * This function attempts to fetch data up to a maximum number of attempts (`maxAttempts`).
     * If the fetched data's hash differs from the current top layer hash, it updates the settings and file share data.
     * It also updates the connection status based on the success or failure of the data retrieval.
     * 
     * The function handles specific errors:
     * - `DataRetrievalError`: Retries the data retrieval up to `maxAttempts`.
     * - `AuthError`: Logs the error and stops further attempts.
     * - Other errors: Logs the error and stops further attempts.
     * 
     * @async
     * @function
     * @name updateFiles
     * @returns {Promise<void>} A promise that resolves when the update process is complete.
     */
    const updateFiles = useCallback(async () => {
      console.log('Updating files')
      let attempts = 0;
      const maxAttempts = 3;
    
      while (attempts < maxAttempts) {
        try {
  
          const data = await getData();
  
          if(hash(data) !== topLayerHash.current){
            setFeSettings({useLims: data.permissions.lims, useExpMode: data.permissions.exp_mode})
            setFileShareData(data.data as IExpData[])
            topLayerHash.current = hash(data)
          }
          setConnectionStatus('connected'); 

          break; // Break the loop if getData succeeds
        } catch (error) {

          attempts++;

          if (attempts >= maxAttempts) {
            console.error('Max retry attempts reached. Giving up.');
            setConnectionStatus('Connection issue: Unable to retrieve data.'); 
            break;
          }    

          console.warn('Attempt', attempts);

          // Check if the error is the specific error we want to retry on
          if (error instanceof Error && error.message === 'DataRetrievalError') {

            console.warn('Error while getting files:', error.message);
      
          } else if (error instanceof Error && error.name === 'AuthError') {

            console.warn('AuthError getting files:', error.name);
            break;
 
          } else {
            // If it's a different error, don't retry and exit the loop
            console.error('Error getting files:', error);
            setConnectionStatus('Connection issue: Unknown error.'); 

            break;
          }
        } 
      } 
    }, [getData]);
  
    const contextValue = useMemo(() => ({
      fileShareData,
      feSettings,
      getContainerClient,
      runAnalyzer,
      runReporter,
      updateData,
      deleteData,
      updateFiles,
      connectionStatus
    }), [fileShareData, feSettings, getContainerClient, runAnalyzer, runReporter, updateData, deleteData, updateFiles, connectionStatus]);
  
    
    return (
      <DataContext.Provider value={contextValue}>
        {children}
      </DataContext.Provider>
    );
  });
  
export { DataContext, DataStorageProvider };
  