/* eslint-disable react/react-in-jsx-scope */
import { UnauthenticatedTemplate, AuthenticatedTemplate } from '@azure/msal-react'
import { NavigationBar } from './NavigationBar'
import { ToastContainerDefault } from './ToastComponent'
import 'react-toastify/dist/ReactToastify.css'
import { DataStorageProvider } from '../contexts/DataContext';
import { SSEProvider } from '../contexts/SSEContext';
import { AuthProvider } from '../contexts/AuthContext';
import React from 'react';

/**
 * PageLayout component that conditionally renders content based on authentication status.
 *
 * @component
 * @param {Object} props - The properties object.
 * @param {React.ReactNode} props.children - The child components to be rendered within the layout.
 * @returns {JSX.Element} The rendered PageLayout component.
 *
 * The layout includes:
 * - A navigation bar.
 * - An image displayed at the bottom right corner, which changes based on authentication status.
 * - When unauthenticated, displays a mission image.
 * - When authenticated, wraps children with AuthProvider, DataStorageProvider, and ConnectSSE components.
 * - A footer with a link to Oncobit Support.
 *
 * @remarks
 * This component utilizes the react-toastify library for toast notifications.
 *
 */
const PageLayout = ({ children }: { children: React.ReactNode }): JSX.Element => {

       return (
        <>
            <div className='content-page'>
                <NavigationBar />
                <br />
                <UnauthenticatedTemplate>
                    <img src="oncobit_mission.png" alt="dots-log" style={{ position: 'fixed', bottom: '0', right: '0', width: '120vh', height: '50%', zIndex: -1 }} />
                </UnauthenticatedTemplate>
                <AuthenticatedTemplate>
                    <img src="dots.png" alt="dots-log" style={{ position: 'fixed', bottom: '0', right: '0', width: '85vh', height: '75%', zIndex: -1 }} />
                    <AuthProvider>
                        <DataStorageProvider>
                            <SSEProvider>
                                {children}
                            </SSEProvider>
                        </DataStorageProvider>
                        <ToastContainerDefault />
                    </AuthProvider>
                </AuthenticatedTemplate>
            </div>

            <footer>
                <center>
                    <a
                        href="https://oncobit.com/en/contact/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        {' '}
                        Oncobit Support
                    </a>
                </center>
            </footer>
        </>
    )
}

export default React.memo(PageLayout);
