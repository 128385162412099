/**
 * FileUploadContent component handles the file upload functionality.
 * It uses the react-dropzone library to manage file drop events and uploads files to Azure Blob Storage.
 *
 * @component
 * @returns {JSX.Element} The rendered component.
 *
 * @remarks
 * - The component uses the DataContext to get the container client and update the file data.
 * - It manages the UI state for enabling/disabling the dropzone, showing upload progress, and displaying uploaded items.
 * - The component handles various error scenarios and provides appropriate user feedback.
 *
 * @component
 */
/* eslint-disable react/react-in-jsx-scope */
import { useState, useEffect, useContext } from 'react'
import { useDropzone, type FileWithPath } from 'react-dropzone'
import { uploadFiles, aggregateFiles, type ExperimentFolder } from '../utils/UploadUtils'
import { DataContext } from '../contexts/DataContext';
import { ContainerClient } from '@azure/storage-blob';
import { protectedResources } from '../authConfig'

export const FileUploadContent = (): JSX.Element => {

  // UI/form management
  const [dropDisabled, setDropDisabled] = useState(true)
  const [uploading, setUploading] = useState(false)
  const [uploadedItems, setUploadedItems] = useState<string[]>([])
  const [containerClientUpload, setContainerClientUpload] = useState<ContainerClient>()
  const { fileShareData, getContainerClient, updateData, updateFiles } = useContext(DataContext);

  useEffect(() => {
    if (containerClientUpload === undefined) {
      setDropDisabled(true)
      getContainerClient(protectedResources.apiUploadService.endpoint).then((containerClient) => {
        setContainerClientUpload(containerClient)
      }).catch((error) => {
        if (error instanceof Error && error.message === 'ContainerClientError') {
          console.debug('Error getting container client:', error.name);
        } else {
          console.error(error)
        }
      })
    } else {
      setDropDisabled(false)
    }
  }, [containerClientUpload, getContainerClient])  

  /**
   * Handles the drop action for file uploads.
   *
   * @param {FileWithPath[]} acceptedFiles - The files that were dropped by the user.
   * @returns {Promise<void>} A promise that resolves when the file upload process is complete.
   *
   * @throws {Error} Throws an error if the file upload process encounters an issue.
   * Possible error messages include:
   * - 'Invalid path': The folder structure is not valid.
   * - 'Invalid ExperimentFolder': The ExperimentFolder must contain valid files.
   * - 'Invalid ExperimentFolder name': The ExperimentFolder name is invalid.
   * - 'Empty ExperimentFolder': The ExperimentFolder is empty.
   */
  const onDropAction = async (acceptedFiles: FileWithPath[]): Promise<void> => {
    try {
      const newFiles = aggregateFiles(acceptedFiles)
      const updateFiles = new Map<string, ExperimentFolder>()

      newFiles.forEach((value: ExperimentFolder, key: string) => {
        if(fileShareData.some((row) => row.experiment_name === key)){
          value.overwrite = window.confirm('Experiment folder already exists. Do you want to overwrite it?')
        }
        updateFiles.set(key, value)
      })

      onFileUpload(updateFiles)

    } catch (exception: unknown) {
      let message: string | undefined = undefined

      if (exception instanceof Error) {
        switch (exception.message) {
          case 'Invalid path':
            message = 'Not a valid folder structure'
            break
          case 'Invalid ExperimentFolder':
            message = 'ExperimentFolder must contain valid files.'
            break
          case 'Invalid ExperimentFolder name':
            message = 'ExperimentFolder name is invalid.'
            break
          case 'Empty ExperimentFolder':
            message = 'ExperimentFolder is empty.'
            break
        }
      } 
      if(message){
        alert(message);
        console.log(message)
      } else {
        throw exception
      }
    }
  }

  /**
   * Handles the file upload process.
   *
   * @param files - A map where the key is the file name and the value is an ExperimentFolder object.
   * @returns A promise that resolves when the upload process is complete.
   *
   * @throws {Error} Throws a 'NetworkError' if a network error occurs during the upload.
   * @throws {Error} Throws an error with the exception name if any other error occurs.
   */
  const onFileUpload = async (files: Map<string, ExperimentFolder>) => {
    if (containerClientUpload !== undefined) {
      try {
          setUploading(true)
          await uploadFiles(files, containerClientUpload)
          setUploadedItems([...uploadedItems])
          await updateData([...files.keys()])
          await updateFiles()
      } catch (error: unknown) {
          setContainerClientUpload(undefined)
          throw new Error('Error in File Upload', { cause: error });
      } finally {
        setUploading(false)
      }
    }
  }


  /**
   * Custom hook to handle file drop functionality using `useDropzone`.
   * 
   * @constant
   * @type {object}
   * @property {function} getRootProps - Returns the props required for the root dropzone element.
   * @property {function} getInputProps - Returns the props required for the input element.
   * @property {boolean} isDragActive - Indicates whether a file is being dragged over the dropzone.
   * 
   * @param {object} options - Configuration options for the dropzone.
   * @param {boolean} options.useFsAccessApi - Temporarily disables the File System Access API.
   * @param {boolean} options.noClick - Disables click-to-upload functionality.
   * @param {boolean} options.noKeyboard - Disables keyboard-to-upload functionality.
   * @param {number} options.maxFiles - Maximum number of files that can be uploaded.
   * @param {number} options.maxSize - Maximum size (in bytes) for each file.
   * @param {boolean} options.disabled - Disables the dropzone.
   * @param {function} options.onDrop - Callback function to handle file drop events.
   */
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    useFsAccessApi: false, // This is a temporary fix for the issue with the useDropzone hook
    noClick: true,
    noKeyboard: true,
    maxFiles: 250,
    maxSize: 250 * 1e6,
    disabled: dropDisabled,
    onDrop: async (acceptedFiles: FileWithPath[]) => {
      onDropAction(acceptedFiles)
    }
  })
  
  // display form
  const DDForm = (): JSX.Element => (
    <div {...getRootProps({ className: 'draganddrop'})}>
      <input {...getInputProps()} data-testid="dropzone-input" />
      {
        uploading ? <p>Uploading data...</p> : (dropDisabled ? <p>Connecting to storage account... </p> : (isDragActive ? <p>Drop the files here ...</p> : <p>Drag and drop experiment folders here</p>))
      }
    </div>
  )

  return (
    <div style={{ height: "100%"}}>
      { DDForm() }
    </div>
  )
  
}
