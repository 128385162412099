import { type BlockBlobClient, type ContainerClient, type BlobDownloadResponseParsed } from '@azure/storage-blob'
import JSZip from 'jszip'
import saveAsPkg from 'file-saver'
const { saveAs } = saveAsPkg

/**
 * Downloads a blob from the given BlockBlobClient.
 *
 * @param {BlockBlobClient} blobClient - The client used to interact with the blob storage.
 * @returns {Promise<Blob>} A promise that resolves to the downloaded Blob.
 * @throws {Error} If the download fails.
 *
 * @example
 * ```typescript
 * const blobClient = new BlockBlobClient(url, credential);
 * try {
 *   const blob = await downloadBlob(blobClient);
 *   // Use the blob
 * } catch (error) {
 *   console.error('Error downloading blob:', error);
 * }
 * ```
 */
export const downloadBlob = async (blobClient: BlockBlobClient): Promise<Blob> | never => {
  // Get file content from position 0 to the end
  // In browsers, get downloaded data by accessing downloadFileResponse.blobBody

  let blob: Blob | undefined
  // Download blob
  try {
    const downloadFileResponse: BlobDownloadResponseParsed = await blobClient.download()
    blob = await downloadFileResponse.blobBody
  } catch (error) {
    console.log('Download failed!', error)
  }

  if (blob !== undefined) {
    console.log('Download successful!')
  } else {
    console.log('Download failed!')
    throw new Error('Download failed!')
  }

  return blob
}

/**
 * Downloads files from a specified container and optionally zips them if multiple files are selected.
 *
 * @param foldername - The name of the folder or the single file to be downloaded.
 * @param filesSelected - An array of filenames to be downloaded from the container.
 * @param containerClient - The Azure Storage ContainerClient instance used to access the blobs.
 * @returns A promise that resolves when the download is complete.
 *
 * @example
 * ```typescript
 * const foldername = 'myFolder';
 * const filesSelected = ['file1.txt', 'file2.txt'];
 * const containerClient = new ContainerClient('https://myaccount.blob.core.windows.net/mycontainer');
 * 
 * downloadFile(foldername, filesSelected, containerClient)
 *   .then(() => console.log('Download complete'))
 *   .catch((error) => console.error('Download failed', error));
 * ```
 */
export const downloadFile = async (foldername: string, filesSelected: string[], containerClient: ContainerClient): Promise<void> => {

  const fileblobs: Array<Promise<Blob>> = [...filesSelected].map(async (name, i) => {
    const blobClient: BlockBlobClient = containerClient.getBlockBlobClient(name)
    return await downloadBlob(blobClient)
  })

  console.log(fileblobs)
  let fileContent: Promise<Blob>
  let fileName: string

  if (foldername !== filesSelected[0]) {
    const zip = new JSZip()

    fileblobs.forEach((fileBlob, i) => {
      zip.file(filesSelected[i], fileBlob)
    })

    fileContent = zip.generateAsync({ type: 'blob' }).then((content) => {
      return (content)
    })
    fileName = foldername + '.zip'
  } else {
    // Create an objectURL
    fileContent = fileblobs[0]
    fileName = filesSelected[0]
  }

  saveAs(await fileContent, fileName)
}


/**
 * Retrieves a map of experiment IDs to their corresponding file names from a given Azure Blob Storage container.
 *
 * @param containerClient - The Azure Blob Storage container client used to list blobs.
 * @param expIds - An array of experiment IDs to filter the blobs.
 * @returns A promise that resolves to a map where the keys are experiment IDs and the values are arrays of file names.
 */
export const getFilesDownload = async (containerClient: ContainerClient, expIds: string[]): Promise<Map<string, string[]>> => {
  const list = containerClient.listBlobsFlat()
  
  let downloadFiles: Map<string, string[]> = new Map<string, string[]>()
  for await (const item of list) {
    const topLayer = item.name.split('/')[0]
    if(expIds.includes(topLayer)){
      downloadFiles.has(topLayer) ? downloadFiles.get(topLayer)?.push(item.name) : downloadFiles.set(topLayer, [item.name])
    }
  }

  return downloadFiles
}

// const getQCReport = async (containerClient: ContainerClient, expId: string): Promise<Blob> => {
//   const list = containerClient.listBlobsFlat()

//   const experiment_name = expId.split('_')[0]

//   const fileName = expId + '/' + experiment_name + '_QC_report.html'
  
//   let downloadFile: string = ""
//   for await (const item of list) {
//     if (item.name === fileName) {
//       downloadFile = item.name;
//       break;
//     }
//   }

//   const blobClient: BlockBlobClient = containerClient.getBlockBlobClient(downloadFile)
//   return await downloadBlob(blobClient)
// }


// export const openQCOnClick = (expId: string[], containerClientDownload: ContainerClient): void => {

//   getQCReport(containerClientDownload, expId[0]).then((blob) => {

//     const blobURL = URL.createObjectURL(blob);
    
//     let win = window.open(blobURL, "QC-Report", "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=780,height=200");
//     if(win) {
//       win.document.body.innerHTML = "HTML"; 
//     }
//   }).catch((error) => {
//     if (error.name === 'AbortError') return
//     console.error('Error getting files:', error)
//   } )

// }


/**
 * Initiates the download of selected items from a specified container client.
 *
 * @param selectedItems - An array of strings representing the selected items to be downloaded.
 * @param containerClientDownload - The container client from which the files will be downloaded.
 *
 * This function retrieves the files associated with the selected items and starts their download.
 * It logs the start and completion of each download, and handles any errors that occur during the process.
 *
 * @returns {void}
 */
export const downloadOnClick = (selectedItems: string[], containerClientDownload: ContainerClient): void => {

  getFilesDownload(containerClientDownload, selectedItems).then((files) => {

    for (const [key, value] of files) {
      console.log('Start download of ' + key)

      if ((key != null) && value.length > 0) {
        downloadFile(key, value, containerClientDownload).then(() => {
          console.log('Download complete')
        }).catch((error) => {
          console.error('Error downloading file:', error)
        })
      }
    }
  } ).catch((error) => {
    if (error.name === 'AbortError') return
    console.log('Error getting files:', error)
  } )
}