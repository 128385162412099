// ButtonsComponent.js
import React, { useContext } from 'react';
import { FileShareContext } from '../contexts/FileShareContext';
import { LaunchAnalyzerButton, LaunchReporterButton, DeleteButton } from "./CustomButtons";

const ButtonsComponent = (): JSX.Element => {
  const {
    selection,
    experimentMode,
    setExperimentMode,
    language,
    setLanguage,
    printMessage,
    feSettings,
    gridRef,
  } = useContext(FileShareContext);

  return (
    <div className="alignButtons">
      <div className="button-section">
        <LaunchAnalyzerButton
          selection={selection}
          options={{ mode: experimentMode ? 'experiment' : 'production' }}
          printMessage={printMessage}
          gridRef={gridRef}
        />
      </div>
      <div className="button-section">
        <LaunchReporterButton
          selection={selection}
          options={{ language: language }}
          printMessage={printMessage}
          gridRef={gridRef}
        />
      </div>
      <div className="button-section2">
        <DeleteButton
          selection={selection}
          printMessage={printMessage}
          gridRef={gridRef}
        />
      </div>
      {feSettings.useExpMode && (
        <>
          <div data-testid="experiment-test" className="overlay-row">
            <label className="switch" title="Use experiment mode!">
              <input
                name="expMode"
                type="checkbox"
                checked={experimentMode}
                onChange={() => setExperimentMode(!experimentMode)}
              />
              <span className="slider round"></span>
            </label>
            <div className="pmanal-text">
              <strong>Exp. Mode</strong>
            </div>
          </div>
        </> )}
      {feSettings.useLims && (
          <>
          <div data-testid="language-test" className="overlay-row">
            <label className="switch" title="Choose report language!">
              <select
                className="language"
                id="language"
                value={language}
                onChange={(e) => setLanguage(e.target.value)}
              >
                <option value="german">German</option>
                <option value="english">English</option>
              </select>
            </label>
            <div className="pmanal-text">
              <strong>Language</strong>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ButtonsComponent;
