// FileShareContent.js
import React, { useMemo } from 'react';
import { FileShareProvider } from '../contexts/FileShareContext';
import AgGridComponent from './AgGridComponent';
import ButtonsComponent from './ButtonsComponent';
import '@ag-grid-community/styles/ag-grid.css'; // Core CSS
import '@ag-grid-community/styles/ag-theme-quartz.css'; // Theme

export const FileShareContent = (): JSX.Element => {
  const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
  return (
    <FileShareProvider>
      <div style={containerStyle} data-testid="FSStyle">
        <AgGridComponent />
        <br />
        <ButtonsComponent />
        <br />
        <br />
        <div>
          <span id="uploadHistory"></span>
        </div>
      </div>
    </FileShareProvider>
  );
};

