import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history'

const browserHistory = createBrowserHistory();
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    connectionString: "InstrumentationKey=ede755fa-518c-4065-b9bf-8044df066472;IngestionEndpoint=https://switzerlandnorth-0.in.applicationinsights.azure.com/;LiveEndpoint=https://switzerlandnorth.livediagnostics.monitor.azure.com/;ApplicationId=cbf9b52f-8952-4c67-af26-4cbf897c0db6",
    extensions: [reactPlugin],
    enableAutoRouteTracking: true,
    disableAjaxTracking: false,
    autoTrackPageVisitTime: true,
    enableCorsCorrelation: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory }
    }
  }
});
appInsights.loadAppInsights();

// appInsights.addTelemetryInitializer((env:ITelemetryItem) => {
//     env.tags = env.tags || [];
//     env.tags["ai.cloud.role"] = "<app-role-frontend>";
//     //custom props
//     env.data = env.data || {};
//     env.data["ms-appName"] = "<frontend-name>";
//     env.data["ms-user"] = "<frontend-auth-user>";
//     env.data["ms-userid"] = "<frontend-auth-userid>";
// });

export { reactPlugin, appInsights };
