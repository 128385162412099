import { downloadOnClick } from "../utils/DownloadUtils";
import React, { useState, useEffect, useContext } from 'react';
import { DataContext } from "../contexts/DataContext";
import { CustomCellRendererProps } from "@ag-grid-community/react";
import { debounce } from '../utils/NavigationUtils';
import { IExpData } from '../types/types';
import { IMsalContext, useIsAuthenticated, withMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { Button } from "react-bootstrap";
import { useRedirectHandlers } from "../hooks/useRedirectHandlers";
import { protectedResources } from '../authConfig'

/**
 * DownloadButton component renders a button that allows users to download data.
 * The button is enabled or disabled based on the availability of data.
 * 
 * @param {CustomCellRendererProps} params - The properties passed to the cell renderer.
 * @returns {JSX.Element} The rendered button component.
 * 
 * @component
 * 
 * @example
 * <DownloadButton params={params} />
 * 
 * @remarks
 * The button is disabled if there is no data available or if a download is in progress.
 * The button's title and icon change based on the availability of data.
 * 
 * @function
 * @name DownloadButton
 * 
 * @param {CustomCellRendererProps} params - The properties passed to the cell renderer.
 * @returns {JSX.Element} The rendered button component.
 */
export const DownloadButton = (params: CustomCellRendererProps): JSX.Element => {
  const { getContainerClient } = useContext(DataContext);
  const [isDisabled, setIsDisabled] = useState(false);

  const resultsAvailable = params.value !== undefined && params.value.length > 0;
  const col_name = params.colDef?.headerName ?? "Column";

  const handleDownloadClick = async () => {
    try {
      const containerClientDownload = await getContainerClient(protectedResources.apiDownloadService.endpoint);
      if (containerClientDownload) {
        downloadOnClick(Array.isArray(params.value) ? params.value : [params.value], containerClientDownload);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <span className="missionSpan">
      <button
        className="button"
        title={(resultsAvailable ? `Download ${col_name} for ` : `${col_name} not available for `) + params.data.experiment_name}
        disabled={!resultsAvailable || isDisabled}
        onClick={async () => {
          setIsDisabled(true);
          await handleDownloadClick();
          setTimeout(() => setIsDisabled(false), 3000);
        }}
      >
        <img
          alt={`${params.value}`}
          src={resultsAvailable ? "/download_icon.png" : "/download_icon_red.png"}
          className="missionIcon"
          width="30"
        />
      </button>
    </span>
  );
};




/**
 * Props for the LaunchButton component.
 * 
 * @interface LaunchButtonProps
 * @property {IExpData[]} selection - An array of selected experimental data.
 * @property {Object} options - Configuration options for the button.
 * @property {boolean} [options.mode] - Optional mode flag.
 * @property {string} [options.language] - Optional language setting.
 * @property {(message: string) => void} printMessage - Function to print a message.
 * @property {React.RefObject<any>} gridRef - Reference to a grid component.
 */
interface LaunchButtonProps {
  selection: IExpData[];
  options?: { mode?: string, language?: string };
  printMessage: (message: string) => void;
  gridRef: React.RefObject<any>;
}

/**
 * LaunchAnalyzerButton is a React functional component that renders a button
 * to trigger the Oncobit PM-Analyzer for selected experiments.
 *
 * @param {LaunchButtonProps} props - The properties passed to the component.
 * @param {Array} props.selection - The selected items to be analyzed.
 * @param {boolean} props.experimentMode - A flag indicating if the experiment mode is enabled.
 * @param {Function} props.printMessage - A function to print messages to the user.
 * @param {Object} props.gridRef - A reference to the grid component.
 *
 * @returns {JSX.Element} The rendered button component.
 *
 * @remarks
 * The button is disabled if no items are selected, if the analyzer is currently running,
 * or if the button was recently clicked (to prevent multiple rapid clicks).
 * The button's title and behavior change based on the selection state.
 * If any selected experiment has already been run, the user is prompted for confirmation
 * before re-launching the analyzer.
 */
export const LaunchAnalyzerButton: React.FC<LaunchButtonProps> = ({
  selection,
  options,
  printMessage,
  gridRef,
}: LaunchButtonProps): JSX.Element => {

    const { runAnalyzer } = useContext(DataContext);
    const [isDisabled, setIsDisabled] = useState(false);
    const [isRunning, setIsRunning] = useState(false);
      
    const doAnalyzer = (selectedItems: number[]): void => {
      runAnalyzer(selectedItems, options!.mode!).then(() => {
        console.log('Triggered run successfully')
      }).catch((error) => {
        console.error('Error running file:', error)
      })
    }

    let launch = false
    const expids = new Map<number, string>();

    let buttonTitle: string

    if (selection.length > 0) {
      launch = selection.some((item) => {
        return item.date_run_analyzer === undefined || item.date_run_analyzer > item.date_upload
      })

      for (const item of selection) {
        expids.set(item.experiment_id, item.experiment_name)
      }

      buttonTitle = `Run Oncobit PM-Analyzer for ${[...expids.values()].join(' & ')}!`
    } else {

      buttonTitle = 'Select a row to run PM Analyzer!'
    }

    useEffect(() => {
      setIsRunning(selection.length > 0 && selection.every((item) => {
        return item.status_analyzer === 'running'
      }))
    }, [selection])

    const handleClickAnal = debounce(() => {
        if (!launch) {
          launch = window.confirm('Some of your selected experiments have already been run.\nAre you sure you want to re-launch?');
        }

        if (launch) {
          printMessage("PM-Analyzer launched for ID: " + [...expids.values()].join(', '));
          console.log("PM-Analyzer launched for ID: " + [...expids.values()].join(', '));
          doAnalyzer([...expids.keys()]);
        }

        gridRef.current?.api.deselectAll();
        setIsDisabled(true)
        setTimeout(() => setIsDisabled(false), 3000);

      }, 300);

    return (
      <button className="button" title={buttonTitle}  onClick={ handleClickAnal } disabled={ isDisabled || selection.length === 0 || isRunning } >
        <div className='pmanal-overlay'>
          <img
            alt={"Run"}
            src={"/pm.svg"}
            className="pmanal-img"
          />
          <div className='pmanal-text'>
            <strong>Analyzer</strong>
          </div>
        </div>
      </button>
    );

};


/**
 * LaunchReporterButton is a React functional component that renders a button to trigger the PM-Reporter.
 * The button is enabled or disabled based on the selection and the status of the selected items.
 * 
 * @param {LaunchButtonProps} props - The properties passed to the component.
 * @param {Array} props.selection - The selected items from the grid.
 * @param {Object} props.options - Additional options for the reporter.
 * @param {Function} props.printMessage - Function to print messages.
 * @param {Object} props.gridRef - Reference to the grid component.
 * 
 * @returns {JSX.Element} A button element that triggers the PM-Reporter when clicked.
 * 
 * @component
 * 
 * @example
 * const selection = [{ experiment_id: 1, experiment_name: 'Exp1', status_analyzer: 'success', status_reporter: 'idle' }];
 * const options = { language: 'en' };
 * const printMessage = (msg) => console.log(msg);
 * const gridRef = useRef(null);
 * 
 * <LaunchReporterButton
 *   selection={selection}
 *   options={options}
 *   printMessage={printMessage}
 *   gridRef={gridRef}
 * />
 */
export const LaunchReporterButton: React.FC<LaunchButtonProps> = ({
  selection,
  options,
  printMessage,
  gridRef,
}: LaunchButtonProps): JSX.Element => {

    const { runReporter } = useContext(DataContext);
    const [isDisabled, setIsDisabled] = useState(false);
    const [isRunning, setIsRunning] = useState(false);
    const expids = new Map<number, string>()

    const doReporter = (selectedItems: number[]): void => {
      runReporter(selectedItems, options!.language!).then(() => {
        console.log('Triggered run successfully')
      }).catch((error) => {
        console.error('Error running file:', error)
      })
    }

    let buttonTitle: string

    if (selection.length > 0) {
      for (const item of selection) {
        if (item.status_analyzer === "success" && item.status_reporter !== "running") {
          expids.set(item.experiment_id, item.experiment_name)
        }
      }
      if (expids.size > 0) {
        buttonTitle = `Run Oncobit PM-Reporter for ${[...expids.values()].join(' & ')}!`
      } else {
        buttonTitle = 'No results available for your selection!'
      }
    } else {
      buttonTitle = 'Select a row to run PM Reporter!'
    }

    useEffect(() => {
      setIsRunning(selection.length > 0 && selection.every((item) => {
        return item.status_reporter === 'running'
      }))
    }, [selection])

    const handleClickRep = debounce(() => {
      const confirmed = window.confirm(`Please confirm that you studied the QC-Reports for the selected experiments: ${[...expids.values()].join(' & ')}!`);
      if (confirmed) {
        printMessage("PM-Reporter launched for ID: " + [...expids.values()].join(', '));
        doReporter([...expids.keys()]);
      }
      gridRef.current?.api.deselectAll();
      setIsDisabled(true)
      setTimeout(() => setIsDisabled(false), 3000);
    }, 300);


    return (
      <button className="button" title={buttonTitle} onClick={ handleClickRep } disabled={isDisabled || expids.size === 0 || isRunning } >
        <div className='pmanal-overlay'>
          <img
            alt={"Run"}
            src={"/pm.svg"}
            className="pmanal-img"
          />
          <div className='pmanal-text'>
            <strong>Reporter</strong>
          </div>
        </div>
      </button>
    );

};


/**
 * DeleteButton component allows users to delete selected experiments.
 * 
 * @param {LaunchButtonProps} props - The properties for the DeleteButton component.
 * @param {Array} props.selection - The selected items to be deleted.
 * @param {Function} props.printMessage - Function to print a message after deletion.
 * @param {Object} props.gridRef - Reference to the grid component.
 * 
 * @returns {JSX.Element} A button that triggers the deletion of selected experiments.
 * 
 * @component
 * 
 * @example
 * const selection = [{ experiment_id: 1, experiment_name: 'Experiment 1' }];
 * const printMessage = (msg) => console.log(msg);
 * const gridRef = useRef(null);
 * 
 * <DeleteButton selection={selection} printMessage={printMessage} gridRef={gridRef} />
 */
export const DeleteButton: React.FC<LaunchButtonProps> = ({
  selection,
  printMessage,
  gridRef,
}: LaunchButtonProps): JSX.Element => {

    const { deleteData } = useContext(DataContext);
    const [isDisabled, setIsDisabled] = useState(true);

    const doDelete = (selectedItems: Map<number, string>): void => {
      deleteData(selectedItems).then((response) => {
        if(response === 0) console.log('Deleted experiments successfully.')
        else window.alert('Error deleting experiments. Try again.')
      })
    }
    
    let expids: Map<number, string> = new Map()
    let buttonTitle: string
    
    if (selection.length > 0) {
      for (const item of selection) {
        expids.set(item.experiment_id, item.experiment_name)
      }
      buttonTitle = `Delete experiments ${[...expids.values()].join(' & ')}!`
    } else {
      buttonTitle = 'Select a row to delete!'
    }

    useEffect(() => {
      setIsDisabled(selection.length === 0)
    }, [selection])


    const handleClickDel = debounce(() => {
      const confirmed = window.confirm(`Please confirm that want to delete the selected experiments: ${[...expids.values()].join(' & ')}!`);
      if (confirmed) {
        printMessage("Experiments deleted: " + [...expids.values()].join(', '));
        doDelete(expids);
      }
      gridRef.current?.api.deselectAll();
      setIsDisabled(true)
      setTimeout(() => setIsDisabled(false), 3000);
    
    }, 300);
            
    return (
      <button className="button" title={ buttonTitle}  onClick={ handleClickDel } disabled={ isDisabled } >
        <img
          alt={"Run"}
          src={"/trash.svg"}
          className='trash-icon'
        />
      </button>
    );
};


/**
 * A button component that handles user sign-in and sign-out actions.
 * 
 * This component uses authentication hooks to determine the current authentication state
 * and displays either a "Sign in" or "Sign out" button accordingly.
 * 
 * @returns {JSX.Element | null} A button element for sign-in or sign-out, or null if the authentication status is in progress.
 * 
 * @component
 * @example
 * // Usage example:
 * <SignInSignOutButton />
 * 
 * @remarks
 * The component uses the `useAuth` hook to access the current active account, login and logout handlers, and the authentication progress status.
 * It also uses the `useIsAuthenticated` hook to check if the user is authenticated.
 * 
 * The sign-in button is not displayed briefly after returning from a redirect sign-in to prevent flickering, 
 * as processing the server response takes a render cycle or two.
 */
export const SignInSignOutButton = ({ msalContext }: { msalContext: IMsalContext }): JSX.Element | null => {
    const { inProgress } = msalContext;
    const { handleLoginRedirect, handleLogoutRedirect } = useRedirectHandlers(msalContext);
    
    const isAuthenticated = useIsAuthenticated();

    if (isAuthenticated) {
        return <>
            <Button className="signout-auto" variant="0074ff" onClick={handleLogoutRedirect}>
                Sign out
            </Button>
        </>
    } else if (!(inProgress === InteractionStatus.Startup || inProgress === InteractionStatus.HandleRedirect)) {
        return <>
            <Button className="signin-auto" variant="0074ff" onClick={handleLoginRedirect}>
                Sign in
            </Button>
        </>
    } else {
        return null;
    }
}

export const SignInSignOutButtonWithMsal = withMsal(SignInSignOutButton);