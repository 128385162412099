// AgGridComponent.js
import React, { useContext, useEffect } from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import { FileShareContext } from '../contexts/FileShareContext';
import { DataContext } from '../contexts/DataContext';
import { IExpData } from '../types/types';

const AgGridComponent = (): JSX.Element => {
  
  const { connectionStatus } = useContext(DataContext);



  const {
    gridRef,
    rowData,
    colDefs,
    defaultColDef,
    setSelection,
  } = useContext(FileShareContext);

  
  const onSelectionChanged = () => {
    const selectedRows = gridRef.current.api.getSelectedRows() as IExpData[];
    setSelection(selectedRows || []);
  };

    // Use effect to trigger overlay update when connectionStatus changes
    useEffect(() => {
      if (gridRef.current?.api) {
        if (connectionStatus !== 'connected') {
          gridRef.current.api.showLoadingOverlay();
          // 
          const overlayElement = document.querySelector('.ag-overlay-loading-center');
          if (overlayElement) {
            overlayElement.innerHTML = connectionStatus;
          }
        } else {
          gridRef.current.api.hideOverlay();
        }
      }
    }, [gridRef, connectionStatus]);

  return (
    <div className="ag-theme-quartz" style={{ height: '100%' }}>
      <AgGridReact
        data-testid="ag-grid"
        ref={gridRef}
        rowData={rowData}
        columnDefs={colDefs}
        defaultColDef={defaultColDef}
        pagination={true}
        paginationPageSize={10}
        paginationPageSizeSelector={[10, 50, 100]}
        rowSelection="multiple"
        onSelectionChanged={onSelectionChanged}
        overlayLoadingTemplate={`<span class="ag-overlay-loading-center">${connectionStatus}</span>`}
        overlayNoRowsTemplate='<span class="ag-overlay-loading-center">Please wait while your data is loading...</span>'
      />
    </div>
  );
};

export default AgGridComponent;