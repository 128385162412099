import { type IMsalContext } from '@azure/msal-react';
import { loginRequest } from '../authConfig';
import { debounce } from '../utils/NavigationUtils';

/**
 * Custom hook that provides handlers for login and logout redirects using MSAL (Microsoft Authentication Library).
 *
 * @param {IMsalContext} msalContext - The MSAL context containing the instance of the MSAL application.
 * @returns {Object} An object containing the following properties:
 * - handleLoginRedirect: A debounced function that triggers a login redirect.
 * - handleLogoutRedirect: A debounced function that triggers a logout redirect.
 */
export const useRedirectHandlers = (msalContext: IMsalContext): {
  handleLoginRedirect: () => void;
  handleLogoutRedirect: () => void;
} => {  const { instance } = msalContext;

  const handleLoginRedirect = debounce(async (): Promise<void> => {
    console.log('Logging in..');
    instance.loginRedirect(loginRequest)
  }, 300);

  const handleLogoutRedirect = debounce(async (): Promise<void> => {
    console.log('Logging out..');
    instance.logoutRedirect();
  }, 300);

  return {
    handleLoginRedirect,
    handleLogoutRedirect,
  };
};

