// SPA/src/components/AuthProvider.tsx
import React, { createContext, useContext } from 'react';
import { useMsalAuthentication, useMsal, IMsalContext } from '@azure/msal-react';
import { InteractionType, AuthenticationResult } from '@azure/msal-browser';
import { protectedResources } from '../authConfig';

interface AuthContextProps {
  result: AuthenticationResult | null;
  error: Error | null;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

/**
 * AuthProvider component that wraps its children with authentication context.
 * It uses MSAL (Microsoft Authentication Library) to handle authentication.
 *
 * @param {Object} props - The properties object.
 * @param {React.ReactNode} props.children - The child components to be wrapped by the AuthProvider.
 * @returns {JSX.Element} The AuthProvider component with authentication context.
 *
 * @remarks
 * This component utilizes the `useMsal` hook to get the MSAL instance and the `useMsalAuthentication`
 * hook to handle silent authentication. It provides the authentication result and error through
 * the AuthContext.Provider.
 *
 * @component
 */
const AuthProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {

  const { instance }: IMsalContext = useMsal()

  const msalRequest = {
    scopes: [
      ...protectedResources.apiDownloadService.scopes.read,
      ...protectedResources.apiUploadService.scopes.read,
      ...protectedResources.apiRunAnalyzerService.scopes.write,
      ...protectedResources.apiRunReporterService.scopes.write,
      ...protectedResources.apiSSEService.scopes.read,
      ...protectedResources.apiUpdateDataService.scopes.write,
      ...protectedResources.apiUpdateDataService.scopes.read,
      ...protectedResources.apiUpdateDataService.scopes.delete
    ]
    }
  
  const { result, error } = useMsalAuthentication(InteractionType.Silent, {
    ...msalRequest,
    account: instance.getActiveAccount() ?? undefined
  });

  return (
    <AuthContext.Provider value={{ result, error }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextProps => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export { AuthProvider };

