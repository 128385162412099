import React from 'react'
import { ToastContainer, type ToastContainerProps } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'


/**
 * Default properties for the ToastContainer component.
 * 
 * @property {string} position - The position of the toast on the screen. Default is 'top-right'.
 * @property {number} autoClose - Time in milliseconds before the toast automatically closes. Default is 5000 ms.
 * @property {boolean} hideProgressBar - Whether to hide the progress bar. Default is false.
 * @property {boolean} newestOnTop - Whether to display the newest toast on top. Default is false.
 * @property {boolean} closeOnClick - Whether the toast should close when clicked. Default is true.
 * @property {boolean} rtl - Whether the toast should support right-to-left layout. Default is false.
 * @property {boolean} pauseOnFocusLoss - Whether the toast should pause when the window loses focus. Default is true.
 * @property {boolean} draggable - Whether the toast is draggable. Default is true.
 * @property {boolean} pauseOnHover - Whether the toast should pause when hovered over. Default is true.
 */
const defaultProps: ToastContainerProps = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  newestOnTop: false,
  closeOnClick: true,
  rtl: false,
  pauseOnFocusLoss: true,
  draggable: true,
  pauseOnHover: true
}

/**
 * A React component that renders a ToastContainer with default properties.
 * 
 * @component
 * @extends React.Component<ToastContainerProps>
 * 
 * @example
 * <ToastContainerDefault />
 * 
 * @remarks
 * This component is a wrapper around the ToastContainer component, applying default properties.
 */
export class ToastContainerDefault extends React.Component<ToastContainerProps> {
  render (): JSX.Element {
    return (
      <ToastContainer {...defaultProps} />
    )
  }
}
